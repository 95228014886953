// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen Förderbeiträge in Basel-Stadt"
        meta={[
          { name: 'description', content: 'Von Förderbeiträgen in Basel-Stadt profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️' },
          { name: 'keywords', content: 'Fördergelder Kanton Basel-Stadt' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='4IcMqgAzqiSNIOjjqqnSHI-6lxsZ8F18JKnlAhlsA8T7z'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "6lxsZ8F18JKnlAhlsA8T7z",
    "updatedAt": "2023-04-20T16:08:34.933Z",
    "title": "Fördergelder Kanton Basel-Stadt",
    "keyword": "Fördergelder",
    "articleType": "Infoartikel",
    "description": "Im Kanton Basel-Stadt profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
    "content": [
        {
            "type": "infoarticle",
            "id": "3fpRmxi5kJqL64zwOgfVPS",
            "updatedAt": "2025-02-20T10:39:06.317Z",
            "title": "Fördergelder Kanton Basel-Stadt",
            "text": "<h3>Fördergelder Kanton Basel-Stadt</h3>\n<p>Luft/Wasser-, Sole/Wasser- und Wasser/Wasser-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Hierbei sind die Anforderungen, Nebenbedingungen und Dimensionierungsgrundlagen gemäss Energieverordnung zu beachten resp. einzuhalten.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag ausserhalb Fernwärmegebiet</th>\n<th>Beitrag innerhalb Fernwärmegebiet</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 8'000 + CHF 250/kWth</td>\n<td>CHF 4'000 + CHF 125/kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser bis 10 kWth</td>\n<td>max. CHF 30'000 pauschal</td>\n<td>max. CHF 15'000 pauschal</td>\n</tr>\n<tr>\n<td>Sole/Wasser ab 10kWth</td>\n<td>CHF 25'000 + CHF 450/kWth</td>\n<td>CHF 12'500 + CHF 225/kWth</td>\n</tr>\n</tbody>\n</table>\n<p>Hinweis: Es werden maximal 40% der Investitionskosten gefördert.</p>\n<p><strong>Hinweis: Wenn ein vollständiger Antrag auf Förderbeiträge für Wärmepumpen im Fernwärmegebiet vor Ablauf der Übergangsfrist am 31. Mai 2025 eingereicht wird, gelten die bisherigen Förderbeiträge. Das heisst, unabhängig davon, ob sich das Haus inner- oder ausserhalb eines Fernwärmegebietes befindet, erhalten Sie die Beiträge für \"Ausserhalb Fernwärmegebiet\". Die Zusage für Förderbeiträge ist zwei Jahre gültig.</strong></p>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://www.bs.ch/wsu/aue/abteilung-energie/foerderbeitraege-energie/waermepumpen\">Link</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Basel-Stadt<br>\nEnergiefachstelle<br>\nHochbergerstrasse 158<br>\n4019 Basel<br>\nTel: 061 639 23 50<br>\n<a href=\"https://www.aue.bs.ch/energie/foerderbeitraege.html\">Website</a></p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "previewImage": {
        "description": "Wappen Fördergelder Wärmepumpe Kanton Basel-Stadt",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4I1oj4cYAtZk01QXbF6tqn/262e1906dd77f93adcb6765b08d5516e/Basel_Stadt.png"
    },
    "previousArticle": "/foerdergelder/kanton-basel-landschaft",
    "nextArticle": "/foerdergelder/kanton-bern"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
